/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { FC, useEffect, useState } from 'react'
import { Tree } from 'antd'
import type { TreeDataNode, TreeProps } from 'antd';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { Box } from '../../../../components/base/Box'
import { getCheckedTerritories } from '../../../../helpers/data/territories';
import { Button } from '../../../../components/button/Button';
import { TerritoriesDto } from '../../../../common/user/User';
import { useUserTerritory } from '../../../../store/users/usersHooks';
import { UsersState } from '../../../../store/users/usersState';
import { useActions } from '../../../../hooks/useActions';
import { loadUser } from '../../../../store/users/usersActions';
import '../Territories.css'



interface TerritoriesProps {
   data: TreeDataNode[];
   onClose?: () => void;
   isDisabled: boolean;
   rowData: any;
}

export const Territories: FC<TerritoriesProps> = ({data, onClose, rowData, isDisabled}) => {
    const [expandedKeys, setExpandedKeys] = useState<React.Key[]>([]);
    const [checkedKeys, setCheckedKeys] = useState<string[] & React.Key[]>([]);
    const [autoExpandParent, setAutoExpandParent] = useState(true);

    const actions = useActions({
      loadUser: loadUser.started,
  });

    const { currentUser } = useSelector((state: UsersState) => state.users)

    const {
      setUserTerritoryStatus,
  } = useUserTerritory()
 

    useEffect(()=> { 
        const checked = getCheckedTerritories(data)     
        setCheckedKeys(checked)
        setExpandedKeys(checked)
      },[data])

    const onExpand: TreeProps['onExpand'] = (expandedKeysValue) => {     
        setExpandedKeys(expandedKeysValue);
        setAutoExpandParent(false);
      };
    
      const onCheck: TreeProps['onCheck'] = (checkedKeysValue) => {
        setCheckedKeys(checkedKeysValue);       
      };

      const formatCheckedKeys = () => {
        const filteredByTerritory = checkedKeys.filter(item => item.includes('#T'))
        return filteredByTerritory.map(checkedKey => {
            const hash = checkedKey.indexOf('#');
            return checkedKey.slice(0, hash)
        })
      }

      const convert = () => {
        const result = rowData.map((item: TerritoriesDto) => {
            const reg = item.regions?.map(region => {
                const terr = region.territories.map(territory => {
                    if(formatCheckedKeys().includes(territory.name)){
                        return {
                            ...territory,
                            active: true
                        }
                    } else {
                        return {
                          ...territory,
                          active: false
                        }
                    }
                    
                })
                return {
                    name: region.name,
                    territories: terr
                }
            })

            return {
                name:item.name,
                regions: reg

            }
   
        })
        return result;
      }

      
    const onSubmit = () => {
      const result = convert()
      setUserTerritoryStatus({ id: currentUser.objectId, payload: result }) 
      setTimeout(() => {
        actions.loadUser(currentUser.objectId)
      }, 1000)     
      
      onClose && onClose();

    }

    return (
        <Box padding={16}>          
            <TreeWrapper>
                <Tree               
                    onExpand={onExpand}
                    expandedKeys={expandedKeys}
                    autoExpandParent={autoExpandParent}
                    onCheck={onCheck}
                    checkedKeys={checkedKeys}
                    treeData={data}                               
                    selectable={false}
                    disabled={isDisabled}
                    checkable
                    showLine                   
                />
            </TreeWrapper>
            {!isDisabled && (<Box paddingTop={25} paddingBottom={25} row>
                <Button variant="primary" type="submit" onClick={onSubmit}>
                    Save
                </Button>
                <CancelWrapper>
                    <Button variant="secondary" type="submit" onClick={onClose}>
                        Cancel
                    </Button>
                </CancelWrapper>
            </Box>)}
            
        </Box>
        
          
    )
}

const TreeWrapper = styled.div`  
    max-height: 400px;
    overflow: auto; 
    padding: 16px;   
`

const CancelWrapper = styled.div`  
    border: 1px solid #d3d3d3;
    border-radius: 5px;
    margin-left: 20px;    
`



