import React, { FC } from 'react'
import { Box } from '../../../../components/base/Box'
import { Form } from '../../../../components/form/Form'
import { Field } from '../../../../components/form/Field'
import { Tooltip } from '../../../../components/tooltip/Tooltip'
import { Checkbox } from '../../../../components/form/Checkbox'
import { SimpleSelect } from '../../../../components/form/SimpleSelect'
import { H3 } from '../../../../components/base/Typography'
import { useForm } from '../../../../hooks/form/useForm'
import { Brand, brandName } from '../../../../common/user/Brand'
import { BrandRole, brandRoleName } from '../../../../common/user/BrandRole'
import { BrandRoleAssignmentOption } from '../../../../services/users/BrandRoleAssignmentOption'
import { useAvailableBrands } from '../../../../store/users/usersHooks'
import { UserFormButtons, UserFormMode } from './UserFormButtons'
import { UserBrandPermissionsGuidance } from './UserBrandPermissionsGuidance'

export interface BrandFormData {
    brand: Brand
    roles: BrandRole[]
    userAccountType?: string
}

interface UserBrandFormProps {
    formMode?: UserFormMode
    initialState: BrandFormData
    brandRolesOptions: BrandRoleAssignmentOption[]
    onSubmit: (data: BrandFormData) => void
    onCancel: () => void
    onRemove?: () => void
    disabled?: boolean
}

export const UserBrandForm: FC<UserBrandFormProps> = ({
    formMode = UserFormMode.Edit,
    initialState,
    brandRolesOptions,
    onSubmit,
    onCancel,
    disabled
}) => {
    const { updateField, values, submitHandler, reset } = useForm<BrandFormData>(
        {},
        {
            initialState: {
                ...initialState,
            },
        },
    )

    const { brandRolesOptions: allBrandsOptions } = useAvailableBrands()

    const brandOptions = allBrandsOptions.map(o => o.brand)

    const updateBrand = (value: Brand) => {
        const newRoleOptions = roleOptionsForBrand(value)
        const newRoles = values.roles ? values.roles.filter(r => newRoleOptions.includes(r)) : []
        updateField('roles', newRoles.length > 0 ? newRoles : [])
        updateField('brand', value)
    }

    const roleOptionsForBrand = (b: Brand) => {
        const item = brandRolesOptions.find(o => o.brand.mainBrand === b.mainBrand)
        if (item) {
            return {
                documents: item.roles.documents,
                business: item.roles.business,
            }
        }
        return {
            documents: [],
            business: [],
        }
    }
    const roleOptions = roleOptionsForBrand(values.brand)

    const updateRoles = (role: BrandRole) => {
        const newRoles = [...values.roles]

        if (newRoles.includes(role)) {
            // If it is, find its index
            const index = newRoles.indexOf(role)
            // And remove it using splice()
            newRoles.splice(index, 1)
        } else {
            // If it's not, add it to the end of the array
            newRoles.push(role)
        }

        updateField('roles', newRoles)
    }

    const toggleAll = (type: 'documents' | 'business', isChecked: boolean) => {
        let newRoles = [...values.roles]

        if (type === 'documents') {
            newRoles = !isChecked
                ? [...values.roles, ...roleOptions.documents]
                : values.roles.filter(role => !roleOptions.documents.includes(role))
        }

        if (type === 'business') {
            newRoles = !isChecked
                ? [...values.roles, ...roleOptions.business]
                : values.roles.filter(role => !roleOptions.business.includes(role))
        }

        updateField('roles', newRoles)
    }

    const handleSubmit = (data: BrandFormData) => {
        onSubmit(data)
        reset()
    }

    const handleCancel = () => {
        reset()
        onCancel()
    }

    const isRoleSelected = (value: BrandRole): boolean => values.roles.some(role => role === value)

    const selectedDocuments = values.roles.filter(role => roleOptions.documents.includes(role))
    const selectedBusiness = values.roles.filter(role => roleOptions.business.includes(role))

    return (
        <Form onSubmit={submitHandler(handleSubmit)}>
            <Box>
                <Box padding={24} justify="space-between" align="flex-start" row>
                    <Box flex={1} paddingRight={16} style={{ maxWidth: '210px' }}>
                        {formMode === UserFormMode.Edit ? (
                            <H3>{brandName(values.brand)}</H3>
                        ) : (
                            <Field label="Brand name">
                                <SimpleSelect
                                    value={values.brand}
                                    options={brandOptions}
                                    labelFormatter={brandName}
                                    onChange={updateBrand}
                                    small
                                />
                            </Field>
                        )}
                    </Box>
                    <Box flex={1} paddingRight={16}>
                        <Box marginBottom={16}>
                            <Tooltip
                                icon="info"
                                title="Permission Guidance"
                                content={
                                    <UserBrandPermissionsGuidance
                                        accountType={initialState.userAccountType}
                                    />
                                }
                                width="476px"
                            />
                        </Box>
                        <Checkbox
                            title="Documents"
                            checked={selectedDocuments.length > 0}
                            onChange={() => toggleAll('documents', selectedDocuments.length > 0)}
                            areSomeChecked={
                                selectedDocuments.length > 0 &&
                                selectedDocuments.length < roleOptions.documents.length
                            }
                        />
                        <div style={{ paddingLeft: '24px' }}>
                            {roleOptions.documents.map(role => (
                                <Checkbox
                                    key={role}
                                    title={brandRoleName(role)}
                                    checked={isRoleSelected(role)}
                                    onChange={() => updateRoles(role)}
                                />
                            ))}
                        </div>
                    </Box>

                    <Box flex={1} paddingRight={16} paddingTop={40}>
                        <Checkbox
                            title="All Business Functions"
                            checked={selectedBusiness.length > 0}
                            onChange={() => toggleAll('business', selectedBusiness.length > 0)}
                            areSomeChecked={
                                selectedBusiness.length > 0 &&
                                selectedBusiness.length < roleOptions.business.length
                            }
                        />
                        <div style={{ paddingLeft: '24px' }}>
                            {roleOptions.business.map(role => (
                                <Checkbox
                                    disabled={disabled && role === BrandRole.Aquant}
                                    key={role}
                                    title={brandRoleName(role)}
                                    checked={isRoleSelected(role)}
                                    onChange={() => updateRoles(role)}
                                />
                            ))}
                        </div>
                    </Box>
                    <Box flex={1} justify="flex-end">
                        <UserFormButtons
                            formMode={formMode}
                            paddingBottom={16}
                            onCancel={handleCancel}
                        />
                    </Box>
                </Box>
            </Box>
        </Form>
    )
}
