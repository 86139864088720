import React, { FC } from 'react'
import styled from 'styled-components'
import { animated, useSpring } from 'react-spring'
import { borderRadius, shadowColor, springConfig, white } from '../../styles/theme'
import { Portal } from '../base/Portal'
import { Overlay } from '../base/Overlay'
import { Positioned } from '../base/Positioned'
import { IconButton } from '../button/IconButton'

interface ModalProps {
    isOpen: boolean
    onClose(): void
}

export const Modal: FC<ModalProps> = ({ isOpen, onClose, children }) => {
    const overlayAnimation = useSpring({
        opacity: isOpen ? 1 : 0,
        config: springConfig,
    })

    const isTerritory = true
    const containerAnimation = useSpring({
        from: {
            opacity: 0,
            transform: 'translate(-50%, -50%) scale(0.95)',
        },
        to: {
            opacity: isOpen ? 1 : 0,
            transform: `translate(-50%, -50%) ${isOpen ? 'scale(1)' : 'scale(0.95)'}`,
        },
        config: springConfig,
    })
    return (
        <Portal>
            <div style={{ pointerEvents: isOpen ? 'all' : 'none'}}>
                <Container style={containerAnimation}>
                    <Positioned right={8} top={8}>
                        <IconButton icon="x" onClick={onClose} color={white} />
                    </Positioned>

                    {children}
                </Container>
                <Overlay onClick={onClose} style={overlayAnimation} withOverlay />
            </div>
        </Portal>
    )
}

const Container = styled(animated.div)`
    top: 50%;
    left: 50%;
    position: absolute;
    z-index: 101;
    background-color: ${white};
    box-shadow: 0 5px 10px ${shadowColor};
    border-radius: ${borderRadius};
    overflow: hidden;
`
