import React, { FC, useEffect, useState } from 'react'
import styled from 'styled-components'
import { EnumValues } from 'enum-values'
import { TreeDataNode } from 'antd'
import { Box } from '../../../components/base/Box'
import { Button } from '../../../components/button/Button'
import { ButtonGroup } from '../../../components/button/ButtonGroup'
import { Field } from '../../../components/form/Field'
import { Form } from '../../../components/form/Form'
import { Input } from '../../../components/form/Input'
import { Spinner } from '../../../components/spinner/Spinner'
import { contentOffset, greyDark2, textColor } from '../../../styles/theme'
import { SimpleSelect } from '../../../components/form/SimpleSelect'
import { useTmsAccountsOptions, useUsersAccounts } from '../../../store/users/usersHooks'
import { Separator } from '../../../components/base/Separator'
import { TerritoriesDto, User } from '../../../common/user/User'
import { TmsAccountOption, TmsAccountWithName } from '../../../common/user/TmsAccountWithName'
import { CountryShort } from '../../../common/user/CountryShort'
import { Tooltip } from '../../../components/tooltip/Tooltip'
import { TerritoriesModal } from '../TerritoriesModal'
import { getCheckedTerritories } from '../../../helpers/data/territories'
import { UserFormTmsAccounts } from './components/UserFormTmsAccounts'
import { UserFormData, useUserForm } from './useUserForm'
import { UserFormTmsAccountsOptions } from './components/UserFormTmsAccountsOptions'
import { Territories } from './components/Territories'
import { Icon } from '../../../components/icon/Icon'

interface UserFormProps {
    user: User
    loading: boolean
    isGlobalAdmin: boolean
    onSubmit: (data: UserFormData, actionType: 'brand' | 'tms') => void
    onCancel: () => void
}

const tooltipContent = `Territory assignment will automatically overwrite user's current ERP accounts with those that correspond to
the selected locations. Manual assignment of ERP account is disabled while managing it by territory. To manage ERP accounts
manually you need to deselect all the territories for the user first.`



export const UserForm: FC<UserFormProps> = ({ user, loading, onSubmit, onCancel }) => {
    const { updateField, fieldError, submitHandler, values } = useUserForm(user)
    const [antTreeData, setAntTreeData] = useState<TreeDataNode[]>([])
    const [isEmailValid, setIsEmailValid] = useState(false)
     const [checkedKeys, setCheckedKeys] = useState([]);
     const [isModalOpen, setIsModalOpen] = useState(false)

    const {
        tmsAccountsOptions,
        tmsVerificationInProgress,
        tmsVerificationFailed,
        verifyPendingTmsAccounts,
        addAndVerifyTmsAccountByNumber,
    } = useTmsAccountsOptions()
    const { loadUserAccountsInProgress } = useUsersAccounts(user)

    const territories = user?.territories

    const label = checkedKeys.length === 0 ? 'Assign Territory': 'Manage ERP acounts by territory'


    const validateEmail = (email: string) => {
        const atSymbol = email?.indexOf('@');
        const domain = email.slice(atSymbol+1);
        if(domain === 'siili.com' || domain === 'terex.com'){
            return true;
        }                 
        return false;
      }
    
      useEffect(() => {
        const isEmailValid = validateEmail(user.emails[0]);
        setIsEmailValid(isEmailValid)
    }, [user])
        

    useEffect(() => {
        const convertToTreeData = (territories: TerritoriesDto[] = [], reg?:boolean, terr?: boolean): TreeDataNode[]=> territories?.map((item: TerritoriesDto, i: number) => ({
                title: item.name,
                // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
                key: `${item.name}${reg?('#R'+(i+1)):(terr && !reg)?'#T'+(i+1):''}`,
                 // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                 ...('active' in item? {active: item.active}: {}),
                children: item.regions? convertToTreeData(item.regions, true): convertToTreeData(item.territories, undefined, true), 
            }));

        const treeData = convertToTreeData(territories);
        setAntTreeData(treeData)
        setCheckedKeys(getCheckedTerritories(treeData))
    }, [territories])
   

    const updateSelectedTmsAccounts = (accounts: TmsAccountOption[]) => {
        updateField('selectedTmsAccounts', accounts)
    }

    const removeUserTmsAccount = (account: TmsAccountWithName) => {
        const indexToRemove = values.userTmsAccounts.findIndex(v =>
            v.id === account.id && v.abbreviation === account.abbreviation && v.name === account.name
        )
        if (indexToRemove > -1) {
            const resAccounts = values.userTmsAccounts.slice(0, indexToRemove).concat(values.userTmsAccounts.slice(indexToRemove + 1));
            updateField('userTmsAccounts', resAccounts)
        }
    }

    const handleVerify = () => {
        verifyPendingTmsAccounts()
    }

    const handleAddAndVerify = (accountNumber: string) => {
        if (!accountNumber) {
            return
        }

        if (!tmsAccountsOptions.find(a => a.number === accountNumber)) {
            addAndVerifyTmsAccountByNumber(accountNumber)
        }
        updateField('newTmsAccountNumber', '')
    }

    const resetTmsAccounts = () => {
        updateField('userTmsAccounts', user.tmsAccounts)
        updateField('newTmsAccountNumber', '')
        updateField('selectedTmsAccounts', [])
    }

    const handleCancel = () => {
        resetTmsAccounts()
        onCancel()
    }

    const openModal = () => {
        if(isEmailValid){
            setIsModalOpen(true)
        }
    }

    const handleCloseModal = () => {
        setIsModalOpen(false)
    }

    return (
        <Form onSubmit={submitHandler(onSubmit)}>
            <Box align="stretch" row>
                <Box flex={1}>
                    <Field label="First name*" error={fieldError('givenName')}>
                        <Input
                            value={values.givenName}
                            onTextChange={value => updateField('givenName', value)}
                            disabled
                        />
                    </Field>
                    <Field label="Last name*" error={fieldError('surname')}>
                        <Input
                            value={values.surname}
                            onTextChange={value => updateField('surname', value)}
                            disabled
                        />
                    </Field>
                    <Field label="Job title" error={fieldError('jobTitle')}>
                        <Input
                            value={values.jobTitle}
                            onTextChange={value => updateField('jobTitle', value)}
                            disabled
                        />
                    </Field>
                    <Field label="Email address">
                        <Input value={user.emails[0]} disabled />
                    </Field>
                    <Field label="Office phone" error={fieldError('telephoneNumber')}>
                        <Input
                            value={values.telephoneNumber}
                            onTextChange={value => updateField('telephoneNumber', value)}
                            disabled
                        />
                    </Field>
                    <Field label="Mobile phone*" error={fieldError('mobile')}>
                        <Input
                            value={values.mobile}
                            onTextChange={value => updateField('mobile', value)}
                            disabled
                        />
                    </Field>
                </Box>
                <Box flex={1} paddingLeft={24}>
                    <Field label="Company name" error={fieldError('companyName')}>
                        <Input
                            value={values.companyName}
                            onTextChange={value => updateField('companyName', value)}
                        />
                    </Field>
                    <Field label="Company address*" error={fieldError('streetAddress')}>
                        <Input
                            value={values.streetAddress}
                            onTextChange={value => updateField('streetAddress', value)}
                            disabled
                        />
                    </Field>
                    <Field label="City*" error={fieldError('city')}>
                        <Input
                            value={values.city}
                            onTextChange={value => updateField('city', value)}
                            disabled
                        />
                    </Field>
                    <Field label="Country*" error={fieldError('country')}>
                        <SimpleSelect
                            options={[undefined, ...EnumValues.getNames(CountryShort)]}
                            value={values.country as string}
                            onChange={value => updateField('country', value as CountryShort)}
                            labelFormatter={(val: string) =>
                                CountryShort[val as keyof typeof CountryShort]
                            }
                            disabled
                        />
                    </Field>
                    <Field label="Zip code*" error={fieldError('postalCode')}>
                        <Input
                            value={values.postalCode}
                            onTextChange={value => updateField('postalCode', value)}
                            disabled
                        />
                    </Field>
                    {/* RFIPI-103 */}
                    {/* isGlobalAdmin && user.accountType === AccountType.LocalAccount && (
                        <Box paddingTop={8}>
                            <Switch
                                checked={values.accountEnabled}
                                onChange={value => updateField('accountEnabled', value)}
                            >
                                Account enabled
                            </Switch>
                        </Box>
                    ) */}
                </Box>
            </Box>
            <Separator paddingVertical={8} />
           
                <Box padding={4} paddingTop={contentOffset} justify='space-between' align='flex-start' row> 
                    <Box>
                        <AccountsHeader>ERP Account Numbers</AccountsHeader>
                        <AssignTerritory onClick={openModal} className={isEmailValid? '': 'disabled'}>{label}</AssignTerritory>
                    </Box>
                    <Tooltip
                        icon="info"
                        title="ERP Assignment Guidance"
                        content={
                            <Box padding={8}>
                                {tooltipContent}
                            </Box>
                            
                        }
                        width="340px"
                        position='top'
                    />
                </Box>
                {checkedKeys.length === 0 ? (<Box>
                    <Box row>
                        <UserFormTmsAccounts
                            userAccounts={values.userTmsAccounts}
                            loadUserAccountsInProgress={loadUserAccountsInProgress}
                            onRemove={removeUserTmsAccount}
                            accountsOptions={tmsAccountsOptions}
                            selectedAccounts={values.selectedTmsAccounts}
                            newAccountNumber={values.newTmsAccountNumber}
                            tmsVerificationInProgress={tmsVerificationInProgress}
                            tmsVerificationFailed={tmsVerificationFailed}
                            onSelect={updateSelectedTmsAccounts}
                            onChange={(value: string) => updateField('newTmsAccountNumber', value)}
                            onVerify={handleVerify}
                            onAddAndVerify={handleAddAndVerify}
                        />
                    
                    </Box>
                    <Separator paddingVertical={8} />
                    <Box row>
                        <UserFormTmsAccountsOptions
                            accountsOptions={tmsAccountsOptions}
                            selectedAccounts={values.selectedTmsAccounts}
                            newAccountNumber={values.newTmsAccountNumber}
                            tmsVerificationInProgress={tmsVerificationInProgress}
                            onSelect={updateSelectedTmsAccounts}
                            onChange={(value: string) => updateField('newTmsAccountNumber', value)}
                            onVerify={handleVerify}
                            onAddAndVerify={handleAddAndVerify}
                        />
                    </Box>
                    <Separator paddingVertical={8} />

                    <Box paddingTop={contentOffset} row>
                        {loading || tmsVerificationInProgress ? (
                            <Spinner />
                        ) : (
                            <ButtonGroup>
                                <Button type="submit" variant="primary" onClick={submitHandler(onSubmit)}>
                                    Save
                                </Button>
                                <Button variant="secondary" onClick={handleCancel}>
                                    Cancel
                                </Button>
                            </ButtonGroup>
                        )}
                    </Box>
                </Box>): ( 
                    <>
                        <Territories data={antTreeData} rowData={territories} isDisabled={true} />
                        <Box justify="flex-end" row> 
                            <IconWrapper onClick={handleCancel}>                  
                                <Icon name="arrow-left" color={textColor} /> 
                            </IconWrapper>                
                        </Box>
                    </>
                    )}
                <TerritoriesModal
                    isModalOpen={isModalOpen}
                    onCloseModal={() => handleCloseModal()}
                    title={label}
                    data={antTreeData}
                    rowData={territories}
                />

        </Form>
    )
}

const AccountsHeader = styled(Box)`
    color: ${greyDark2};
    font-size: 20px;
    margin-bottom: 8px;
`

const AssignTerritory = styled.div`
    color: #cc0000;
    font-size: 12px;
    margin-bottom: 8px;
    &::after {
        content: '>';      
         border-color: #cc0000;
         margin-left: 10px;
    }
    &:hover {
        cursor: pointer;
    }
    &.disabled {
        color: #cc000075 !important;
        cursor: auto;
  }
`

const IconWrapper = styled.span` 
    &:hover {
        cursor: pointer;
    } 
    margin-top:30px;
`

