import styled from 'styled-components'
import React, { FC, useRef, useState } from 'react'
import { Popover } from '../../../../components/popover/Popover'
import { Td, Tr } from '../../../../components/table/Table'
import { H3 } from '../../../../components/base/Typography'
import { Chip } from '../../../../components/chip/Chip'
import { useHotkey } from '../../../../hooks/useHotkey'
import { black } from '../../../../styles/theme'

import { BrandRoleAssignmentOption } from '../../../../services/users/BrandRoleAssignmentOption'
import { BrandRolesAssignment } from '../../../../common/user/User'
import { brandName } from '../../../../common/user/Brand'
import { brandRoleName } from '../../../../common/user/BrandRole'
import { UserRowEditHint } from './UserRowEditHint'
import { BrandFormData, UserBrandForm } from './UserBrandForm'

interface UserBrandRowProps {
    canEdit: boolean
    brandRolesOptions: BrandRoleAssignmentOption[]
    userAccountType: string
    brandRoles: BrandRolesAssignment
    onSubmit: (data: BrandFormData) => void
    onRemove: () => void
    disabled: boolean
}

export const UserBrandRow: FC<UserBrandRowProps> = ({
    brandRoles,
    userAccountType,
    onSubmit,
    onRemove,
    canEdit,
    brandRolesOptions,
    disabled,
}) => {
    const ref = useRef<HTMLTableRowElement>(null)

    const [show, setShow] = useState(false)
    const toggle = () => {
        if (canEdit) {
            setShow(!show)
        }
    }
    useHotkey(show && 'Esc', () => toggle(), [show])

    const handleSubmit = (data: BrandFormData) => {        
        toggle()
        onSubmit(data)
    }
    const handleRemove = () => {
        toggle()
        onRemove()
    }
    const handleCancel = () => {
        toggle()
    }

    const userBrand = brandRolesOptions.find(
        item => item.brand.mainBrand === brandRoles.brand.mainBrand,
    )
    
    return (
        <>
            <Tr ref={ref} onClick={toggle} role="button" disabled={!canEdit} pointer>
                <Cell>
                    <UserBrandTitle>{brandName(brandRoles.brand)}</UserBrandTitle>
                </Cell>
                <PermissionsCell>
                    {userBrand?.roles.documents.map(role => (
                        <Chip
                            key={role}
                            marginRight={16}
                            marginBottom={16}
                            label={brandRoleName(role)}
                            active={brandRoles.roles.includes(role)}
                        />
                    ))}
                </PermissionsCell>
                <PermissionsCell>
                {userBrand?.roles.business.map(role => (
                        <Chip
                            key={role}
                            marginRight={16}
                            marginBottom={16}
                            label={brandRoleName(role)}
                            active={brandRoles.roles.includes(role)}
                        />
                    ))}
                </PermissionsCell>
                <UserRowEditHint variant="brand" canEdit />
            </Tr>
            <Popover el={ref} show={show} onClose={handleCancel} padding={8}>
                {show && (
                    <UserBrandForm
                        initialState={{
                            brand: brandRoles.brand,
                            roles: brandRoles.roles,
                            userAccountType,
                        }}
                        disabled={disabled}
                        brandRolesOptions={brandRolesOptions}
                        onSubmit={handleSubmit}
                        onRemove={handleRemove}
                        onCancel={handleCancel}
                    />
                )}
            </Popover>
        </>
    )
}

const Cell = styled(Td)`
    vertical-align: top;
    padding: 32px 16px;
`

const PermissionsCell = styled(Td)`
    padding: 36px 16px 16px;
`

const UserBrandTitle = styled(H3)`
    color: ${black};
`
