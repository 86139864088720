/* eslint-disable @typescript-eslint/prefer-for-of */
import { TreeDataNode } from "antd";

  export const getCheckedTerritories = (data: TreeDataNode[]) => { 
    const result: TreeDataNode[]=[];              
    const checked = (tree: TreeDataNode[]) => {   
        for (let i = 0; i < tree.length; i++) {
          const node = tree[i];
          if(node.active){
            result.push(node.key)
          }
          if (node.children.length > 0) {
            const subRes = checked(node.children)
            result.push(subRes)
          }
      
        }
      return result
    }
       
  return checked(data).filter(item => typeof item !== 'object')
}