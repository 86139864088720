import React, { FC } from 'react'
import styled from 'styled-components';
import { TreeDataNode } from 'antd';
import { Modal } from '../../components/modal/Modal';
import { Box } from '../../components/base/Box';
import { Icon } from '../../components/icon/Icon';
import { Button } from '../../components/button/Button';
import { Territories } from './details/components/Territories';


interface TerritoriesModalProps {
    isModalOpen: boolean;
    onCloseModal: () => void
    title: string;
    data: TreeDataNode[]
    rowData: any
}

export const TerritoriesModal: FC<TerritoriesModalProps> = ({isModalOpen, onCloseModal, title, data, rowData}) => {

    const onClose = () => {
        onCloseModal()
        
    }

    

    return (
        <Modal isOpen={isModalOpen} onClose={onClose}>
            <Wrapper>
                <Box padding={16}>
                    <Box justify='space-between' align='baseline' paddingLeft={16} paddingRight={16}>
                        <TerritoriesHeader>{title}</TerritoriesHeader>  
                        <CloseBtn onClick={onClose}> <Icon name='x' size={16}/></CloseBtn>
                    </Box>           
                    <Territories data={data} onClose={onClose} rowData={rowData} isDisabled={false}/>
                </Box>
           </Wrapper>
        </Modal>
        
    )
}

export const TerritoriesHeader = styled.h1`
    font-weight: 400;
    font-size: 18px;
    line-height: 1.8;
    margin-bottom: 5px;
    margin-top: 0px;
`

const CloseBtn = styled.span`  
    font-size: 12px;
    &:hover{
        cursor: pointer;
    }    
`

const Wrapper = styled.div`  
    min-width: 500px; 
    padding-top: 10px;   
`