import { format } from 'url'
import { Observable, of, throwError, timer } from 'rxjs'
import { ignoreElements, switchMap, mergeMap, retryWhen, tap, delayWhen } from 'rxjs/operators'
import { toast } from 'react-toastify';
import { BaseRestService } from '../base/BaseRestService'
import { KeyAccountActions, UsersExportRequest, UsersExportResponseStatus, UsersExportResponseStatuses, UsersRequest } from '../../common/user/UserRequest'
import { brandToString } from '../../common/user/Brand'
import { ObjectIdAware, User, UserUpdatePayload } from '../../common/user/User'
import { TmsAccountOption, TmsAccountsRequestQuery } from '../../common/user/TmsAccountWithName'
import { Pageable } from '../../common/pageable/Pageable'
import { downloadFileFromResponse } from '../../helpers/download'
import 'react-toastify/dist/ReactToastify.css';


toast.configure()
const notifyToast = (message: string) => toast(message, { type: "info", isLoading: true, toastId: "export-inprogress" });
const successToast = (message: string) => toast(message, { type: "success", toastId: "export-success" });
const errorToast = (message: string) => toast(message, { type: "error", toastId: "export-error" });

class UserService {
    private rest = new BaseRestService()


    public findAll = (r: UsersRequest): Observable<Pageable<User>> =>
        this.rest.getAndTransform<Pageable<User>>(
            format({
                pathname: '/users',
                query: {
                    q: r.q,
                    brand: r.brand ? brandToString(r.brand) : undefined,
                    page: r.page,
                    pageSize: r.pageSize,
                    filters: r.filters,
                },
            }),
        )

    public exportAll = (r: UsersExportRequest): Observable<UsersExportResponseStatus> =>
        this.rest
            .getExportStatus(
                format({
                    pathname: '/users/export',
                    query: {
                        q: r.q,
                        brand: r.brand ? brandToString(r.brand) : undefined,
                        filters: r.filters,
                    },
                }),
            )
            .pipe(mergeMap((status): Observable<UsersExportResponseStatus> => {
                if (status === UsersExportResponseStatuses.READY) {
                    toast.dismiss();
                    successToast("Users export is ready to download! It will start automatically");
                    return this.rest.getAndDownload(
                        format({
                            pathname: '/users/export/download',
                        }),
                    )
                        .pipe(tap(downloadFileFromResponse), ignoreElements())
                }

                throw status;
            }),
                retryWhen(error =>
                    error.pipe(
                        mergeMap((error) => {
                            if (error !== UsersExportResponseStatuses.FAILED) {
                                return of(error);
                            }
                            toast.dismiss();
                            errorToast("Users export failed! It will not be downloadable");
                            return throwError(error);
                        }),
                        tap(status => {
                            notifyToast("Users export is in progress and will take a while so please be patient");
                            console.log(`Status ${status}, retry export!`)
                        }),
                        delayWhen(() => timer(2500))
                    )
                ),
                ignoreElements())

    public findOne = (id: string): Observable<User> =>
        this.rest.getAndTransform<User>(`/users/${id}`)

    public find = (id: string): Observable<User> => this.rest.getAndTransform<User>(`/users/${id}`)

    public deleteUser = (id: string): Observable<any> => this.rest.deleteReq(`/users/${id}`)

    public setKeyAccountStatus = (id: string, actionType: KeyAccountActions): Observable<any> => {
        return this.rest.post(`/users/key-account/${actionType}/${id}`);
    }

    public setUserTerritoryStatus = (id: string, payload: any): Observable<any> => {
        return this.rest.postJsonAndTransform(`/users/territory/${id}`, payload);
    }
    
    public verifyTmsAccounts = (
        accountsQuery: TmsAccountsRequestQuery,
    ): Observable<TmsAccountOption[]> => {
        if (accountsQuery.accountNumber && accountsQuery.accountNumber.length === 0) {
            return of([])
        }

        return this.rest.postJsonAndTransform<TmsAccountOption[]>(
            format({
                pathname: `/users/accounts`,
            }),
            accountsQuery,
        )
    }

    public update = ({
        objectId,
        ...payload
    }: UserUpdatePayload & ObjectIdAware): Observable<User> => {
        let optimisedPayload: Partial<UserUpdatePayload> = {} as UserUpdatePayload
        const { actionType } = payload
        if (actionType === 'brand') {
            optimisedPayload = {
                actionType,
                accountEnabled: payload.accountEnabled,
                brandRoles: payload.brandRoles,
                portalRoles: payload.portalRoles
            }
        } else if (actionType === 'tms') {
            optimisedPayload = {
                actionType,
                accountEnabled: payload.accountEnabled,
                requestedTmsAccountNumbers: payload.requestedTmsAccountNumbers,
                tmsAccounts: payload.tmsAccounts
            }
        } else {
            optimisedPayload = payload
        }
        return this.rest
            .patchJson(
                format({
                    pathname: `/users/${objectId}`,
                }),
                optimisedPayload,
            )
            .pipe(switchMap(() => this.find(objectId)))
    }

}

export const usersService = new UserService()
