import styled from 'styled-components'
import React, { FC, useRef, useState } from 'react'
import { lighten } from 'polished'
import { Icon } from '../../../../components/icon/Icon'
import { Popover } from '../../../../components/popover/Popover'
import { useHotkey } from '../../../../hooks/useHotkey'
import { brandColor, fontFamily, transitionFast } from '../../../../styles/theme'
import { BrandRoleAssignmentOption } from '../../../../services/users/BrandRoleAssignmentOption'
import { useAvailableBrands } from '../../../../store/users/usersHooks'
import { BrandFormData, UserBrandForm } from './UserBrandForm'
import { UserFormMode } from './UserFormButtons'

interface UserBrandAddProps {
    brandRolesOptions: BrandRoleAssignmentOption[]
    onSubmit: (data: BrandFormData, actionType: 'brand' | 'tms') => void
    disabled: boolean
}

export const UserBrandAdd: FC<UserBrandAddProps> = ({ onSubmit, brandRolesOptions, disabled }) => {
    const ref = useRef<HTMLTableRowElement>(null)

    const { brandRolesOptions: allBrandsOptions } = useAvailableBrands()

    const brandOptions = allBrandsOptions.map(o => o.brand)

    const [show, setShow] = useState(false)
    const toggle = () => {
        setShow(!show)
    }
    useHotkey(show && 'Esc', () => toggle(), [show])

    const handleSubmit = (data: BrandFormData) => {        
        toggle()
        onSubmit(data, 'brand')
    }
    const handleCancel = () => {
        toggle()
    }

    return (
        <Container ref={ref}>
            <AddBrandButton onClick={toggle}>
                <Icon name="plus" />
                <ButtonLabel>Add permission</ButtonLabel>
            </AddBrandButton>
            <Popover el={ref} show={show} onClose={handleCancel} topOffset={16} padding={8}>
                <UserBrandForm
                    formMode={UserFormMode.AddBrand}
                    initialState={{
                        brand: brandOptions[0],
                        roles: [],
                    }}
                    brandRolesOptions={brandRolesOptions}
                    onSubmit={handleSubmit}
                    onCancel={handleCancel}
                    disabled={disabled}
                />
            </Popover>
        </Container>
    )
}

const Container = styled.div``

const AddBrandButton = styled.button`
    display: block;
    padding: 12px 16px;
    width: 100%;
    border: none;
    border-bottom: 1px solid ${brandColor};
    font-size: 13px;
    font-family: ${fontFamily};
    text-align: left;
    color: ${brandColor};
    background: transparent;
    cursor: pointer;
    transition: ${transitionFast} all;

    &:hover,
    &:focus {
        outline: none;
        background-color: ${lighten(0.55, brandColor)};
    }
`

const ButtonLabel = styled.span`
    vertical-align: middle;
`
