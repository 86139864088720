import { Pageable } from '../../common/pageable/Pageable'
import { User } from '../../common/user/User'
import { TmsAccountOption, TmsAccountWithName } from '../../common/user/TmsAccountWithName'
import { UsersRequest, usersRequestDefault } from '../../common/user/UserRequest'

export interface UsersState {
    users: Pageable<User>
    loadingAll: boolean
    updateInProgress: boolean
    currentUser?: User
    loadUserAccountsInProgress: boolean
    currentUserAccounts: TmsAccountWithName[]
    filters: UsersRequest
    tmsAccountsOptions: TmsAccountOption[]
    tmsVerificationInProgress: boolean
    tmsVerificationFailed: boolean
    updateKeyAccountSuccess: boolean,
    updateKeyAccountInProgress: boolean,
    updateKeyAccountFailed: boolean,
    updateUserTerritoryFailed: boolean 
    updateUserTerritoryInProgress: boolean
    updateUserTerritorySuccess: boolean
}

export interface UsersStateAware {
    users: UsersState
}

export const emptyUsersPageable: Pageable<User> = {
    items: [],
    total: 0,
    page: 0,
    pageSize: 20,
}

export const initialUsersState: UsersState = {
    users: emptyUsersPageable,
    loadingAll: false,
    updateInProgress: false,
    loadUserAccountsInProgress: false,
    currentUserAccounts: [],
    filters: usersRequestDefault,
    tmsAccountsOptions: [],
    tmsVerificationInProgress: false,
    tmsVerificationFailed: false,
    updateKeyAccountSuccess: true,
    updateKeyAccountInProgress: false,
    updateKeyAccountFailed: false,
    updateUserTerritoryFailed: false, 
    updateUserTerritoryInProgress: false, 
    updateUserTerritorySuccess: false,
}
