import { Action } from 'redux'
import { combineEpics, StateObservable } from 'redux-observable'
import { Observable } from 'rxjs'
import { debounceTime, filter, ignoreElements, map, switchMap } from 'rxjs/operators'
import { asyncLoadEpic } from '../../helpers/observables/asyncLoadEpic'
import { usersService } from '../../services/users/UserService'
import {
    addAndVerifyTmsAccount,
    deleteUser,
    exportUsers,
    load,
    loadUser,
    loadUserAccounts,
    setFilters,
    setKeyAccountStatus,
    setUserTerritoryStatus,
    update,
    verifyTmsAccounts,
} from './usersActions'
import { filterSelectors } from './usersSelectors'
import { UsersStateAware } from './usersState'
import { KeyAccountReq, UpdateKeyAccountStatus, UpdateUserTerritoryStatus, UserTerritoryReq } from '../../common/user/User'

const loadUsersEpic = asyncLoadEpic(load, usersService.findAll)
const loadUserEpic = asyncLoadEpic(loadUser, usersService.findOne)
const loadUserAccountsEpic = asyncLoadEpic(loadUserAccounts, usersService.verifyTmsAccounts)

const userUserEpic = asyncLoadEpic(update, usersService.update)

const setFiltersEpic = (actions$: Observable<Action>) =>
    actions$.pipe(
        filter(setFilters.match),
        debounceTime(250),
        map(a => load.started(a.payload)),
    )

const exportEpic = (actions$: Observable<Action>) =>
    actions$.pipe(
        filter(exportUsers.match),
        switchMap(({ payload }) => usersService.exportAll(payload).pipe(ignoreElements())),
    )

const deleteUserEpic = (actions$: Observable<Action>, state$: StateObservable<UsersStateAware>) =>
    actions$.pipe(
        filter(deleteUser.started.match),
        switchMap(({ payload }) => usersService.deleteUser(payload)),
        map(() => load.started(filterSelectors(state$.value))),
    )

const setKeyAccountStatusEpic = asyncLoadEpic<KeyAccountReq, UpdateKeyAccountStatus, any>(
    setKeyAccountStatus,
    (payload) => usersService.setKeyAccountStatus(payload.id, payload.actionType)
)

const setUserTerritoryStatusEpic = asyncLoadEpic<UserTerritoryReq, UpdateUserTerritoryStatus, any>(
    setUserTerritoryStatus,
    (payload) => usersService.setUserTerritoryStatus(payload.id, payload.payload)
)
const verifyTmsAccountsEpic = asyncLoadEpic(verifyTmsAccounts, usersService.verifyTmsAccounts)
const addAndVerifyTmsAccountEpic = asyncLoadEpic(
    addAndVerifyTmsAccount,
    usersService.verifyTmsAccounts,
)

export const usersEpics = combineEpics(
    loadUsersEpic,
    deleteUserEpic,
    userUserEpic,
    loadUserEpic,
    loadUserAccountsEpic,
    setFiltersEpic,
    exportEpic,
    verifyTmsAccountsEpic,
    addAndVerifyTmsAccountEpic,
    setKeyAccountStatusEpic, 
    setUserTerritoryStatusEpic,
)
