import styled from 'styled-components'
import { usePopper } from 'react-popper'
import React, { ReactNode, FC, useState } from 'react'
import { useHover } from '../../hooks/useHover'
import { genieBlue, greyDark, boxShadowColor } from '../../styles/theme'
import { Icon, IconName } from '../icon/Icon'

interface TooltipProps {
    icon: IconName
    title?: string
    content: ReactNode
    width?: string
    position?: Position
}

type Position =
  | 'auto'
  | 'auto-start'
  | 'auto-end'
  | 'top'
  | 'top-start'
  | 'top-end'
  | 'bottom'
  | 'bottom-start'
  | 'bottom-end'
  | 'right'
  | 'right-start'
  | 'right-end'
  | 'left'
  | 'left-start'
  | 'left-end';

export const Tooltip: FC<TooltipProps> = ({ icon, title, content, width = '100%', position = 'left' }) => {
    const [hoverRef, isHovered] = useHover<HTMLDivElement>()
    const [referenceElement, setReferenceElement] = useState<HTMLDivElement | null>(null)
    const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null)
    const [arrowElement, setArrowElement] = useState<HTMLDivElement | null>(null)
    const { styles, attributes } = usePopper(referenceElement, popperElement, {
        placement: position,
        modifiers: [
            {
                name: 'offset',
                options: {
                    offset: [10, 10],
                },
            },
            { name: 'arrow', options: { element: arrowElement } },
        ],
    })

    return (
        <TooltipContainer ref={hoverRef}>
            <TooltipPlaceholder ref={setReferenceElement}>
                <Icon name={icon} color={genieBlue} />
                {title && <TooltipTitle>{title}</TooltipTitle>}
            </TooltipPlaceholder>
            {isHovered && (
                <TooltipContent
                    ref={setPopperElement}
                    style={styles.popper}
                    width={width}
                    {...attributes.popper}
                >
                    {content}
                    <TooltipArrow ref={setArrowElement} style={styles.arrow} />
                </TooltipContent>
            )}
        </TooltipContainer>
    )
}

const TooltipContainer = styled.div`
    cursor: pointer;
`
const TooltipPlaceholder = styled.div`
    display: flex;
    align-items: center;
`
const TooltipTitle = styled.span`
    margin-left: 12px;
    font-size: 12px;
    color: ${genieBlue};
`

const TooltipContent = styled.div<{ width: string }>`
    z-index: 1;
    width: ${props => props.width};
    max-width: 475px;
    padding: 8px 12px;
    margin-top: 16px;
    background: ${greyDark};
    border-radius: 4px;
    box-shadow: ${boxShadowColor};
    color: white;
    font-size: 12px;
    line-height: 16px;
    cursor: default;
`

const TooltipArrow = styled.div`
    margin-top: -12px;
    right: -5px;

    &::before {
        content: '';
        display: block;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 6px 0 6px 6px;
        border-color: transparent transparent transparent ${greyDark};
    }
`
