import { isEqual, uniqWith } from 'lodash'
import { Brand } from './Brand'
import { BrandRole } from './BrandRole'
import { Country } from './Country'
import { Portal } from './Portal'
import { PortalRole } from './PortalRole'
import { Role } from './Role'
import { TmsAccountWithName } from './TmsAccountWithName'
import { CountryShort } from './CountryShort'
import { KeyAccountActions } from './UserRequest'

export interface ObjectIdAware {
    objectId: string
}

export interface UserInfo {
    givenName: string
    surname: string
    jobTitle: string
    companyName: string
    useTelematics: string

    streetAddress: string
    city: string
    country: Country | CountryShort
    postalCode: string

    telephoneNumber: string
    mobile: string
}

export enum AccountType {
    Invitation = 'Invitation',
    LocalAccount = 'LocalAccount',
    AdUser = 'AdUser',
}

export interface KeyAccountReq {
    id: string
    actionType: KeyAccountActions
}

export interface UserTerritoryReq {
    id: string
    payload: any
}

export enum UpdateKeyAccountStatus {
    Pending = 'Pending',
    Success = 'Success',
    Failed = 'Failed',
}

export enum UpdateUserTerritoryStatus {
    Pending = 'Pending',
    Success = 'Success',
    Failed = 'Failed',
}

export interface User extends UserInfo {
    objectId: string
    accountEnabled: boolean
    accountType: AccountType
    emails: string[]
    requestedPortals: Portal[]
    brandRoles: BrandRoleAssignment[]
    portalRoles: PortalRoleAssignment[]
    roles: Role[]
    createdAt: string
    lastLogin?: string
    requestedTmsAccountNumbers: string[]
    tmsAccounts: TmsAccountWithName[]
    machineSerialNumbers: string[]
    validationAccount: string
    useTelematics: string
    isKeyAccount: boolean
    keyAccounts: KeyAccount[]   
    territories: Territory[]

}



export interface KeyAccountBrand {
    pk: number
    role: BrandRole
    mainBrand: Brand
}

export interface BrandRoleAssignment {
    role: BrandRole
    brand: Brand
}

export interface BrandRolesAssignment {
    roles: BrandRole[]
    brand: Brand
}

export interface PortalRoleAssignment {
    role: PortalRole
    portal: Portal
}

export interface PortalRolesAssignment {
    roles: PortalRole[]
    portal: Portal
}

export interface UserUpdatePayload extends Partial<UserPayload> {
    actionType?: 'brand' | 'tms' | 'portal'
    accountEnabled?: boolean
    brandRoles: BrandRoleAssignment[]
    portalRoles: PortalRoleAssignment[]
    roles: Role[]
    tmsAccounts: TmsAccountWithName[]
}

export interface KeyAccount {
    userId: string;
    email: string;
    firstName: string;
    lastName: string;
    isKeyAccount: boolean;
    lastLoginDate: Date | null;
    platform: string | null;
    machineSerialNumbers: string[]
    requestedErpAccountNumbers: string[]
    brandRoles: KeyAccountBrand[];
    erpAccounts: TmsAccountWithName[];
}

interface Territory {
    name: string;
    active: boolean 
 }

export interface Regions {
    name: string;
    territories: Territory[] 
 }

export interface TerritoriesDto {
   name: string;
   regions: Regions[] 
}

export interface Tree {
    title: string;
    key: string;
    active?: boolean;
    children: any 
 }


export interface UserPayload extends UserInfo {
    accountEnabled: boolean
    brandRoles: BrandRoleAssignment[]
    portalRoles: PortalRoleAssignment[]
    roles: Role[]
    tmsAccounts: TmsAccountWithName[]
    requestedTmsAccountNumbers: string[]
}

export const uniqBrandRole = (brandRoles: BrandRoleAssignment[]): BrandRoleAssignment[] =>
    uniqWith(brandRoles, isEqual)

export const uniqBrands = (brandRoles: BrandRoleAssignment[]): Brand[] =>
    uniqWith(
        brandRoles.map(b => b.brand),
        isEqual,
    )

export const uniqPortalRoles = (assignments: PortalRoleAssignment[]): PortalRoleAssignment[] =>
    uniqWith(assignments, isEqual)

export const uniqPortals = (assignments: PortalRoleAssignment[]): Portal[] =>
    uniqWith(
        assignments.map(a => a.portal),
        isEqual,
    )

export const groupedBrandRoles = (brandRoles: BrandRoleAssignment[]): BrandRolesAssignment[] =>
    uniqBrands(brandRoles).map(brand => ({
        brand,
        roles: brandRoles.filter(br => isEqual(br.brand, brand)).map(br => br.role),
    }))

export const ungroupedBrandRoles = (
    groupedBrandRoles: BrandRolesAssignment[],
): BrandRoleAssignment[] =>
    groupedBrandRoles.flatMap(gr => gr.roles.map(role => ({ brand: gr.brand, role })))

export const groupedPortalRoles = (assignments: PortalRoleAssignment[]): PortalRolesAssignment[] =>
    uniqPortals(assignments).map(portal => ({
        portal,
        roles: assignments.filter(a => isEqual(a.portal, portal)).map(a => a.role),
    }))

export const ungroupedPortalRoles = (
    assignments: PortalRolesAssignment[],
): PortalRoleAssignment[] =>
    assignments.flatMap(a => a.roles.map(role => ({ portal: a.portal, role })))

export const forbiddenEmails = [
    'terex.com',
    'gmail.com',
    'yahoo.com',
    'outlook.com',
    'hotmail.com',
    'icloud.com',
    'aol.com',
    'mail.com',
    'zoho.com',
    'yandex.com',
    'protonmail.com',
    'gmx.com',
    'live.com',
    'msn.com',
    'comcast.net',
    'att.net',
    'verizon.net',
    'me.com',
    'mac.com',
    'mail.ru',
]