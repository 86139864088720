import styled from 'styled-components'
import React, { FC, useEffect, useState } from 'react'
import { H1 } from '../../../components/base/Typography'
import { Box } from '../../../components/base/Box'
import { greyDark2, greyLine2, titleFontFamily } from '../../../styles/theme'
import { forbiddenEmails, User } from '../../../common/user/User'
import { CountryShort } from '../../../common/user/CountryShort'
import { UserTmsAccountsVerificationStatus } from './components/UserTmsAccountsVerificationStatus'
import { Switch } from '../../../components/form/Switch'
import { useHotkey } from '../../../hooks/useHotkey'
import { useKeyAccounts } from '../../../store/users/usersHooks'
import { KeyAccountActions } from '../../../common/user/UserRequest'
import { KeyAccountModal } from '../KeyAccountModal'
import { ErrorSpan } from './components/UserBrandFormAccountElements'

interface UserInfoProps {
    user: User
}

export const UserInfo: FC<UserInfoProps> = ({ user }) => {
    const {
        setKeyAccountStatus,
        updateKeyAccountFailed,
        updateKeyAccountInProgress,
        updateKeyAccountSuccess,
    } = useKeyAccounts()
    const [show, setShow] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const toggle = () => {
        setShow(!show)
    }

    const onSubmit = (actionType: KeyAccountActions) => {
        setKeyAccountStatus({ id: user.objectId, actionType })
        setIsModalOpen(false)
    }

    useHotkey(show && 'Esc', () => toggle(), [show])

    const handleSwitchChange = () => {
        setIsModalOpen(!isModalOpen)
        toggle()
    }

    useEffect(() => {
        setShow(user.isKeyAccount)
    }, [user.isKeyAccount])

    useEffect(() => {
        if (updateKeyAccountFailed) {
            setShow(!show)
        }
    }, [updateKeyAccountFailed, updateKeyAccountInProgress, updateKeyAccountSuccess])

    const handleCloseModal = () => {
        setIsModalOpen(false)
        toggle()
    }

    const userEmail = user.emails?.[0]
    const userDomain = userEmail ? userEmail.split('@').pop() : undefined

    const isForbiddenEmail = userDomain ? forbiddenEmails.includes(userDomain) : true

    return (
        <>
            <Box marginBottom={32} column>
                <InfoText>{user.jobTitle}</InfoText>
                <UserName>{`${user.givenName} ${user.surname}`}</UserName>
                <InfoText>{user.emails.join(', ')}</InfoText>
            </Box>

            {user.telephoneNumber && (
                <Box marginBottom={16} column>
                    <span>Office phone</span>
                    <InfoText>{user.telephoneNumber}</InfoText>
                </Box>
            )}

            {user.mobile && (
                <Box marginBottom={32} column>
                    <span>Mobile phone</span>
                    <InfoText>{user.mobile}</InfoText>
                </Box>
            )}

            <Box marginBottom={32} column>
                <UserCompany>{user.companyName}</UserCompany>
                <InfoText>{user.streetAddress}</InfoText>
                <InfoText>
                    {user.city}
                    {user.postalCode && `, ${user.postalCode}`}
                </InfoText>
                <InfoText>{CountryShort[user.country as keyof typeof CountryShort]}</InfoText>
            </Box>

            <Box marginBottom={16} column>
                <UserTmsAccountsVerificationStatus
                    requestedTmsAccountNumbers={user.requestedTmsAccountNumbers}
                />
            </Box>

            <Box row>
                <Switch checked={show} onChange={handleSwitchChange} disabled={isForbiddenEmail} />
                Key Account
            </Box>

            <KeyAccountModal
                isOpen={isModalOpen && show}
                title={`Are you sure you want to assign Admin role to ${user.givenName} ${user.surname}?`}
                description="This role gives the user permissions to manage Dealers in their Dealership."
                buttonLabel="Assign"
                onSubmit={() => onSubmit(KeyAccountActions['ASSIGN'])}
                onClose={() => handleCloseModal()}
            />

            <KeyAccountModal
                isOpen={isModalOpen && !show}
                title={`Are you sure you want to remove Admin role from ${user.givenName} ${user.surname}?`}
                description={`This user has ${user.keyAccounts.length} dealers in their Dealership. All of those will be discharged from the group and remain without one. You will be able to reassign them to another Dealership.`}
                buttonLabel="Remove"
                onSubmit={() => onSubmit(KeyAccountActions['UNASSIGN'])}
                onClose={() => handleCloseModal()}
            />

            {updateKeyAccountFailed && !updateKeyAccountSuccess && (
                <ErrorSpan>
                    There was an error while updating key account status. Accounts may not be
                    displayed and veryfied properly. Please try again later.
                </ErrorSpan>
            )}

            {isForbiddenEmail && (
                <ErrorSpan>
                    The user with this email address cannot be assigned as a key account because the
                    email is in the list of forbidden addresses. Please choose a different user or
                    contact support for further assistance.
                </ErrorSpan>
            )}
        </>
    )
}

const UserName = styled(H1)`
    font-family: ${titleFontFamily};
    line-height: 40px;
    color: ${greyLine2};
    margin: 0;
`

const InfoText = styled.span`
    color: ${greyDark2};
`

const UserCompany = styled(InfoText)`
    font-size: 16px;
    line-height: 20px;
`
