import React, { FC } from 'react'
import { Modal } from '../../components/modal/Modal'
import { Button } from '../../components/button/Button'
import { Box } from '../../components/base/Box'
import styled from 'styled-components'

interface KeyAccountModalProps {
    isOpen: boolean
    onClose: () => void
    onSubmit: () => void
    title: string
    description: string
    buttonLabel: string
}

export const KeyAccountModal: FC<KeyAccountModalProps> = ({
    isOpen,
    onClose,
    onSubmit,
    title,
    description,
    buttonLabel
}) => (
    <Modal isOpen={isOpen} onClose={onClose}>
        <Box padding={32}>
            <KeyAccountModalHeader>
                {title}
            </KeyAccountModalHeader>
            <KeyAccountModalDescription>
                {description}
            </KeyAccountModalDescription>
            <KeyAccountButtonContainer row>
                <Button variant="secondary" type="submit" onClick={onClose}>
                    <Box row>Cancel</Box>
                </Button>
                <Button variant="primary" type="submit" onClick={onSubmit}>
                    <Box row>{buttonLabel}</Box>
                </Button>
            </KeyAccountButtonContainer>
        </Box>
    </Modal>
)

export const KeyAccountModalHeader = styled.h1`
    font-weight: 400;
    font-size: 18px;
    line-height: 1.8;
    margin-bottom: 16px;
    margin-top: 0px;
`

export const KeyAccountModalDescription = styled.p`
    font-weight: 400;
    font-size: 14px;
    line-height: 1.6;
`
export const KeyAccountButtonContainer = styled(Box)`
    grid-gap: 20px;
`