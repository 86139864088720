import { EnumValues } from 'enum-values'

export interface Brand {
    mainBrand: MainBrand
    subBrand?: SubBrand
}

export enum MainBrand {
    AdvanceMixer = 'ADVANCE-MIXER',
    Awp = 'AWP',
    Bidwell = 'BIDWELL',
    Cbi = 'CBI',
    Ecotec = 'ECOTEC',
    Evoquip = 'EVOQUIP',
    Finlay = 'FINLAY',
    Franna = 'FRANNA',
    Fuchs = 'FUCHS',
    Mds = 'MDS',
    // Mps = 'MPS',
    Powerscreen = 'POWERSCREEN',
    ProStack = 'PROSTACK',
    // TowerCranes = 'CRANES',
    Trs = 'TRS',
    Tws = 'TWS',
    Utilities = 'UTILITIES',
    Marco = 'MARCO',
    RT_Cranes = 'CRANES|ROUGH-TERRAIN-CRANES',
    Tower_Cranes = 'CRANES|TOWER-CRANES',
    Green_Tec = 'GREEN-TEC',
    Canica = 'TEREX-MPS|CANICA',
    Cedarapids = 'TEREX-MPS|CEDARAPIDS',
    Simplicity = 'TEREX-MPS|SIMPLICITY',
    Terex = 'TEREX-MPS|TEREX',
    Jaques = 'JAQUES',
    Magna = 'MAGNA'
}

export type SubBrand = MpsSubbrand // | CranesSubbrand

export enum MpsSubbrand {
    // Canica = 'CANICA',
    // Cedarapids = 'CEDARAPIDS',
    // Simplicity = 'SIMPLICITY',
    // Terex = 'TEREX',
}

export enum CranesSubbrand {
    TowerCranes = 'TOWER-CRANES',
    RoughTerrainCranes = 'ROUGH-TERRAIN-CRANES',
}

export const mainBrandName: Record<MainBrand, string> = {
    [MainBrand.AdvanceMixer]: 'Advance',
    [MainBrand.Awp]: 'Genie',
    [MainBrand.Bidwell]: 'Bid-Well',
    [MainBrand.Cbi]: 'CBI',
    [MainBrand.Ecotec]: 'Terex Ecotec',
    [MainBrand.Evoquip]: 'EvoQuip',
    [MainBrand.Finlay]: 'Finlay',
    [MainBrand.Franna]: 'Franna',
    [MainBrand.Fuchs]: 'Fuchs',
    // [MainBrand.Mps]: 'Terex MPS',
    [MainBrand.Powerscreen]: 'Powerscreen',
    [MainBrand.ProStack]: 'ProStack',
    [MainBrand.Tws]: 'Terex Washing Systems',
    // [MainBrand.TowerCranes]: 'Terex Cranes',
    [MainBrand.Utilities]: 'Terex Utilities',
    [MainBrand.Mds]: 'MDS',
    [MainBrand.Trs]: 'Terex Recycling Systems',
    [MainBrand.Marco]: 'Marco Mfg',
    [MainBrand.RT_Cranes]: 'Terex Cranes | Rough Terrain Cranes',
    [MainBrand.Tower_Cranes]: 'Terex Cranes | Tower Cranes',
    [MainBrand.Green_Tec]: 'Green-Tec',
    [MainBrand.Canica]: 'Canica',
    [MainBrand.Cedarapids]: 'Cedarapids',
    [MainBrand.Simplicity]: 'Simplicity',
    [MainBrand.Terex]: 'Terex MPS',
    [MainBrand.Jaques]: 'Jaques',
    [MainBrand.Magna]: 'Magna'
}

const subBrandName: Record<SubBrand, string> = {
//     [MpsSubbrand.Canica]: 'Canica',
//     [MpsSubbrand.Cedarapids]: 'Cedarapids',
//     [MpsSubbrand.Simplicity]: 'Simplicity',
//     [MpsSubbrand.Terex]: 'Terex',
//     [CranesSubbrand.TowerCranes]: 'Tower Cranes',
//     [CranesSubbrand.RoughTerrainCranes]: 'Rough Terrain Cranes',
}

const brandSubBrand: Partial<Record<MainBrand, SubBrand[]>> = {
    // [MainBrand.Mps]: EnumValues.getValues(MpsSubbrand),
    // [MainBrand.TowerCranes]: EnumValues.getValues(CranesSubbrand),
    // [MainBrand.RT_Cranes]: [CranesSubbrand.RoughTerrainCranes],
    // [MainBrand.Tower_Cranes]: [CranesSubbrand.TowerCranes],
}

export const brandName = (r: Brand) => {
    if (r.subBrand) {
        return `${mainBrandName[r.mainBrand]} | ${subBrandName[r.subBrand]}`
    }
    return mainBrandName[r.mainBrand]
}

export const allBrands = (): Brand[] =>
    allMainBrands.flatMap(mainBrand => {
        if (hasSubBrand(mainBrand)) {
            return [...brandSubBrand[mainBrand]!.map(subBrand => ({ mainBrand, subBrand }))]
        }
        return [{ mainBrand }]
    })

export const allMainBrands: MainBrand[] = EnumValues.getValues<MainBrand>(MainBrand).sort((a, b) =>
    mainBrandName[a].localeCompare(mainBrandName[b]),
)

export const hasSubBrand = (mainBrand: MainBrand) => !!brandSubBrand[mainBrand]

export const getSubBrands = (mainBrand: MainBrand): Brand[] =>
    (brandSubBrand[mainBrand] ?? []).map(subBrand => ({ mainBrand, subBrand }))

export const brandToString = (b: Brand) => {
    if (b.subBrand) {
        return `${b.mainBrand}|${b.subBrand}`
    }
    return b.mainBrand
}

export const isValidBrand = (brand: string): brand is MainBrand =>
    EnumValues.getValues(MainBrand).includes(brand)
