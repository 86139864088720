import React, { FC, useEffect } from 'react'
import { Box } from '../../../components/base/Box'
import { FullscreenSpinner } from '../../../components/spinner/FullscreenSpinner'
import { Table, Tbody, Thead } from '../../../components/table/Table'
import { useAvailableBrands, useBrandActions, useUsers } from '../../../store/users/usersHooks'
import { useBrandsActiveRoles } from '../../../store/brands/brandsHooks'
import { groupedBrandRoles, User } from '../../../common/user/User'
import { UserBrandAdd } from './components/UserBrandAdd'
import { UserBrandRow } from './components/UserBrandRow'
import { UserRowHeader } from './components/UserRowHeader'

interface UserBrandsProps {
    user: User
}

export const UserBrands: FC<UserBrandsProps> = ({ user }) => {
    const { updateInProgress } = useUsers()
    const { brandsActiveRoles, loadBrandsActiveRoles } = useBrandsActiveRoles()

    const { canEditBrandRole } = useAvailableBrands()

    useEffect(() => {
        loadBrandsActiveRoles()
    }, [])

    const { addUserBrand, updateUserBrands, removeUserBrand } = useBrandActions(user)

    return (
        <Box paddingVertical={8} relative>
            {updateInProgress && <FullscreenSpinner />}
            <Table>
                {!!user.brandRoles.length && (
                    <Thead>
                        <UserRowHeader variant="Brand" />
                    </Thead>
                )}
                <Tbody>
                    {groupedBrandRoles(user.brandRoles).map((brandRoles, index) => (
                        <UserBrandRow
                            key={index}
                            canEdit={canEditBrandRole(brandRoles)}
                            brandRolesOptions={brandsActiveRoles}
                            userAccountType={user.accountType}
                            brandRoles={brandRoles}
                            onSubmit={data => updateUserBrands(data, index, 'brand')}
                            onRemove={() => removeUserBrand(index, 'brand')}
                            disabled={user.emails.some(email => email.endsWith("@terex.com"))}
                        />
                    ))}
                </Tbody>
            </Table>
            {brandsActiveRoles.length !== 0 && (
                <UserBrandAdd
                    brandRolesOptions={brandsActiveRoles}
                    onSubmit={addUserBrand}                             
                    disabled={user.emails.some(email => email.endsWith("@terex.com"))}
                />
            )}
        </Box>
    )
}
